import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "../../withRouter";

import ProductPrice from './product-price';
import ProductPriceButton from './product-price-button';

const Product = inject("stores") (
    observer (
        class Product extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_API_PUBLIC;
                
                this.storeAuth = this.props.stores.storeAuth;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeDiscount = this.props.stores.storeDiscount;
                this.storeCart = this.props.stores.storeCart;

                this.state = {
                    
                }
            }

            componentDidMount = async() => {
                const priceDiscounted = this.storeDiscount.showPriceDiscount(this.props.product);

                this.setState({
                    priceDiscounted: priceDiscounted
                })
            }

            componentDidUpdate = () => {

            }

            downloadPdf = (lang) => {
                const file = this.urlPublic + "/public/pdf/leafletProgesterine-" + lang + ".pdf";
                window.open(file);
            }

            gotoProduct = (sku) => {
                const url = "/product/" + sku;
                this.props.navigate(url);
            }

            addToCart = async() => {
                let price = this.props.product.priceIncl;
                let reseller = false;

                if (parseInt(this.storeAuth.user.isReseller) === 1) {
                    price = this.state.priceDiscounted;
                    reseller = true;
                } else {
                    price = this.props.product.priceIncl; 
                    reseller = false;
                }

                this.storeCart.addToCart(this.props.product, price, reseller);
            }
        
            render() {
                return (
                    <div
                        key = {this.props.key}
                        className = "wrapper-product product"
                    >
                        <div
                            className = "product__image"
                        >
                            <img 
                                src = {this.urlPublic + "/public/images/product/" + this.props.product.sku + ".jpg"} 
                                className = {"--link"}
                                onClick = {() => this.gotoProduct(this.props.product.sku)}
                            />
                        </div>
                        <div
                            className = "product__data"
                        >
                            <div
                                className = "product__titles --link">
                                <h2
                                    className = {"product__title"}
                                    dangerouslySetInnerHTML={{ __html: this.props.product.title}}
                                    onClick = {() => this.gotoProduct(this.props.product.sku)}
                                />
                                <span
                                    className = {"product__subtitle"}
                                    dangerouslySetInnerHTML={{ __html: this.props.product.subtitle}}
                                    onClick = {() => this.gotoProduct(this.props.product.sku)}
                                />
                            </div>
                            {this.props.product.discount === 0 &&
                                <ProductPrice
                                    product = {this.props.product}
                                    view = {'regular'}
                                />
                            }
                            <ProductPrice
                                product = {this.props.product}
                                view = {'discount'}
                            />
                            <ProductPrice
                                product = {this.props.product}
                                view = {'wholesale'}
                            />
                            <ProductPriceButton
                                product = {this.props.product}
                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Product);
