import * as apifetch from './fetch.js';

export async function getCartId(accountId) {
    const urlEndpoint = '/v2/carts/id';

    const postData = {
        accountId: accountId
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function getCartItems(cartId) {
    const urlEndpoint = '/v2/carts/list';

    const postData = {
        cartId: cartId
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function getCartAddresses(cartId) {
    const urlEndpoint = '/v2/carts/address';

    const postData = {
        cartId: cartId
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}


export async function updateCart(cartId, cartItem) {
    const urlEndpoint = '/v2/carts/update';

    const postData = {
        cartId: cartId,
        sku: cartItem.sku,
        amount: cartItem.amount,
        discount: cartItem.discount
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function deleteCart(cartId) {
    const urlEndpoint = '/v2/carts/delete';

    const postData = {
        cartId: cartId
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function deleteCartItem(cartId, sku) {
    const urlEndpoint = '/v2/carts/deleteitem';

    const postData = {
        cartId: cartId,
        sku: sku
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function updateCartAddresses(cartId, user) {
    const urlEndpoint = '/v2/carts/user';

    const postData = {
        cartId: cartId,
        user: user
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function updateCartAddressShipping(cartId, address) {
    const urlEndpoint = '/v2/carts/addressshipping';

    const postData = {
        cartId: cartId,
        address: address
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function addToCart(cartId, accountId, product, price, reseller) {
    const urlEndpoint = '/v2/carts/add';

    const postData = {
        cartId: cartId,
        accountId: accountId,
        product: product,
        price: price,
        reseller: reseller
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function saveCartTotals(cartId, subtotal, shipping, total, vat) {
    const urlEndpoint = '/v2/carts/totals';

    const postData = {
        cartId: cartId,
        subtotal: subtotal, 
        shipping: shipping, 
        total: total, 
        vat: vat
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function saveCartData(accountId, cartId, email) {
    const urlEndpoint = '/v2/carts/data';

    const postData = {
        accountId: accountId,
        cartId: cartId,
        email: email
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}