import React, { Component } from 'react';
import { runInAction, reaction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import validate from '../../utilities/validation-rules';
import * as ui from '../../utilities/ui';

import TemplateForms from '../../templates/template-forms';
import RowText from '../form-rows/row-text';
import Select from '../../elements/form-input-select';
import RowSelect from '../form-rows/row-select';
import FormShipping from './form-shipping';

const FormCheckout = inject("stores") (
    observer (
        class FormCheckout extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;
                this.storeCart = this.props.stores.storeCart;
                this.storeShipping = this.props.stores.storeShipping;

                this.fieldTitles = [
                    'profile',
                    'company',
                    'address'
                ];

                this.state = {
                    form: 'accountupdate',
                    formControls: { 
                        invoiceFirstname: {
                            type: "text",
                            group: 1,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceFirstname
                                :   '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        invoiceMiddlename: {
                            type: "text",
                            group: 1,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceMiddlename
                                :   '',
                            validationRules: {
                            },
                            valid: true
                        }, 
                        invoiceLastname: {
                            type: "text",
                            group: 1,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceLastname
                                :   '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },         
                        invoiceEmail: {
                            type: "email",
                            group: 1,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceEmail
                                :   '',
                            validationRules: {
                                isRequired: this.storeAuth.isLoggedin ? false : true,
                                isEmail: true
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: this.storeAuth.isLoggedin ? true : false,
                            readonly: this.storeAuth.isLoggedin ? true : false
                        },
                        invoiceEmail2: {
                            type: "email",
                            group: 1,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceEmail
                                :   '',
                            validationRules: {
                                isRequired: this.storeAuth.isLoggedin ? false : true,
                                isEmail: true
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: this.storeAuth.isLoggedin ? true : false,
                            disable: ["paste"]
                        },
                        invoicePhonenr: {
                            type: "text",
                            group: 1,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoicePhonenr
                                :   '',
                            validationRules: {  
                                isRequired: this.storeAuth.isLoggedin ? false : true,
                                isPhoneNumber: true
                            },
                            valid: true
                        },
                        invoiceCompany: {
                            type: "text",
                            group: 2,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceCompany
                                :   '',
                            validationRules: {  
                            },
                            valid: true
                        },
                        invoiceAddress: {
                            type: "text",
                            group: 3,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceAddress
                                :   '',
                            validationRules: {  
                                isRequired: true
                            },
                            valid: false
                        },
                        invoiceHousenr: {
                            type: "text",
                            group: 3,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceHousenr
                                :   '',
                            validationRules: {  
                                isRequired: true
                            },
                            valid: false
                        },
                        invoiceAddition: {
                            type: "text",
                            group: 3,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceAddition
                                :   '',
                            validationRules: {  
                            },
                            valid: true
                        },
                        invoiceZipcode: {
                            type: "text",
                            group: 3,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceZipcode
                                :   '',
                            validationRules: {  
                                isRequired: true
                            },
                            valid: false
                        },
                        invoiceCity: {
                            type: "text",
                            group: 3,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceCity
                                :   '',
                            validationRules: {  
                                isRequired: true
                            },
                            valid: false
                        },
                        invoiceCountry_select: {
                            type: "select",
                            group: 3,
                            label: i18n.t("forms.label.country"),
                            // value: this.storeCart.shippingCountryCode,
                            // value: this.storeAuth.isLoggedin 
                            //     ?   this.props.user.invoiceCountryCode === '' || this.props.user.invoiceCountryCode === null
                            //         ?   'NL'
                            //         :   this.props.user.invoiceCountryCode
                            //     :   '',
                            // value: this.storeAuth.isLoggedin 
                            //     ?   this.props.user.invoiceCountryCode
                            //     :   '',
                            value: (this.props.user && this.props.user.invoiceCountryCode === '') || (this.props.user && this.props.user.invoiceCountryCode === null)
                            ?   ''
                            :   this.props.user && this.props.user.invoiceCountryCode,
                            placeholder: i18n.t("forms.placeholder.country"),
                            options: this.storeGeneral.listCountries,
                            validationRules: {  
                                isRequired: true
                            },
                            valid: true
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false,
                    error_email: false,
                    error_email2: false,
                    error_email_match: false,
                    error_pw_match: false,
                    classFeedback: '',
                    shippingClass: false
                }
            }

            componentDidMount = async() => {
                this.initCartAddresses();

                const cartAddresses = await this.storeCart.getCartAddresses();
                this.setState({
                    cartAddresses: cartAddresses
                }, () => this.initForm())
            }

            componentWillUnmount = () => {
                //this.storeUser.clearFormFeedback();
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.user !== this.props.user) {
                    let updatedControls = this.state.formControls;

                    Object.keys(updatedControls).map((control, i) => {
                        if (control in this.props.user) { 
                            updatedControls[control].value = this.props.user[control];
                        }
                    });

                    if (this.storeAuth.isLoggedin) {
                        updatedControls['invoiceEmail2'].value = this.props.user['invoiceEmail'];
                    }

                    this.setState({
                        formControls: updatedControls,
                    }, () => this.initForm()); 
                }

                if (prevProps.shippingCountryCode !== this.props.shippingCountryCode) {
                    let formIsValid = this.state.formIsValid;

                    // const checkValid = await this.validateForm();
                    // console.log('SbC valid check', checkValid, this.state.formIsValid, this.props.shippingCountryCode)
                    // if (this.props.shippingCountryCode === '00') {
                    //     formIsValid = false;
                    // } 

                    this.setState({
                        formIsValid: formIsValid
                    }, () => this.recalcData(formIsValid)); 
                }
            }

            initCartAddresses = () => {
                let updatedControls = this.state.formControls;

                Object.keys(updatedControls).map((control, i) => {
                    if (control in this.props.user) { 
                        updatedControls[control].value = this.props.user[control];
                    }
                });

                if (this.props.addresses && this.props.addresses.shippingAddress !== undefined && this.props.addresses.shippingAddress !== null && this.props.addresses.shippingAddress !== '') {
                    this.showFormShippingAddress();
                }

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            initForm = () => {
                const updatedControls = this.storeForms.initForm(this.state.formControls);

                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateRow = (name, value, valid, touched) => {  
                this.storeUi.clearFormFeedback();
                let updatedControls;

                updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid, touched);

                this.setState({
                    error: null,
                    formControls: updatedControls,
                }, () => this.validateForm()); 
               
            }

            validateForm = async() => {
                let emailAvailable = true;
                let emailAvailable2 = true;
                let matchingEmail;
                let error_email_match;

                let matchingPw;
                let error_pw_match;
                if (this.state.formControls.invoiceEmail.value !== '' && this.state.formControls.invoiceEmail2.value !== '') {
                    matchingEmail = ui.checkMatchingValues(this.state.formControls.invoiceEmail.value, this.state.formControls.invoiceEmail2.value);
                    error_email_match = !matchingEmail;
                }

                let formIsValid = matchingEmail && emailAvailable && emailAvailable2 && this.storeForms.validateForm(this.state.formControls);

                if (!this.storeShipping.hasShippingAddress) {
                    if (this.state.formControls.invoiceCountry_select.value === '00') {
                        formIsValid = false;
                    }
                } else {
                    if (this.storeCart.shippingCountryCode === '00') {
                        formIsValid = false;
                    } else {
                        formIsValid = true;
                    }
                }

                this.setState({
                    error_firstname: this.state.formControls.invoiceFirstname.value.length >= 2 ? false : this.state.error_firstname,
                    error_email: !emailAvailable,
                    error_email2: !emailAvailable2,
                    error_email_match: error_email_match,
                    error_pw_match: error_pw_match,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                }, () => this.recalcData(formIsValid));
            }

            recalcData = async(formIsValid) => {
                this.props.formIsValid(formIsValid);

                const result = await this.updateCartUser();
                const cartAddresses = await this.storeCart.getCartAddresses();

                if (cartAddresses) {
                    if ((cartAddresses.shippingAddress === '' || cartAddresses.shippingAddress === null || cartAddresses.shippingCountry === '00' || cartAddresses.shippingCountry === '')) {
                        this.storeShipping.setHasShippingAddress(false);
                        //this.storeCart.setShippingCountryCode(this.state.formControls.invoiceCountry_select.value);
                        this.storeCart.setShippingCountryCode('');
                    } else {
                        this.storeShipping.setHasShippingAddress(true);
                        this.storeCart.setShippingCountryCode(this.props.shippingCountryCode);
                    }
                }

                this.setState({
                    cartAddresses: cartAddresses
                });
            }

            async checkEmail(value) {
                let emailAvailable = await this.storeUser.checkEmailExists(value);
                return emailAvailable;    
            }

            handleBlur = () => {
            }

            handleDropdown = (action, countryCode) => {
                this.storeShipping.setInvoiceCountryCode = countryCode;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls['invoiceCountry_select']
                };
                updatedFormElement.value = countryCode;
                updatedControls['invoiceCountry_select'] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            getCountryValue = (countryCode) => {
                let value = '00';

                if (countryCode !== null && countryCode !== 'undefined'  && countryCode !== undefined && countryCode !== '') {
                    const idx = this.storeGeneral.listCountries.findIndex(country => country.code == countryCode);
                    value = this.storeGeneral.listCountries[idx].name;
                }

                return value;
            }

            updateCartUser = async() => {
                const formControls = this.state.formControls;
                const cartUser = {
                    firstname: formControls.invoiceFirstname.value,
                    middlename: formControls.invoiceMiddlename.value,
                    lastname: formControls.invoiceLastname.value,
                    email: formControls.invoiceEmail.value,
                    phonenr: formControls.invoicePhonenr.value,
                    address: formControls.invoiceAddress.value,
                    housenr: formControls.invoiceHousenr.value,
                    addition: formControls.invoiceAddition.value,
                    zipcode: formControls.invoiceZipcode.value,
                    city: formControls.invoiceCity.value,
                    country: formControls.invoiceCountry_select.value,
                    countryCode: formControls.invoiceCountry_select.value
                }

                const country = this.getCountryValue(this.state.formControls.invoiceCountry_select.value);
                const form = 'accountupdate';

                let registered = await this.storeUser.updateUser(this.state.formControls, country, form);
                let result = await this.storeCart.updateCartUser(cartUser);
                
                //SbC set email in cart
                this.storeCart.saveCartData(formControls.invoiceEmail.value);

                this.storeCart.updateCartAddresses();
                this.storeCart.setInvoiceCountryCode(formControls.invoiceCountry_select.value);
            }

            handleClose = (e) => {
                this.setState({
                    classFeedback: '--inline'
                })
            }

            toggleShipping = async(e) => {
                e.preventDefault();

                const cartAddresses = await this.storeCart.getCartAddresses();
                if (cartAddresses.shippingAddress !== '' && cartAddresses.shippingCity !== '' && this.storeCart.shippingCountryCode !== '' && this.storeCart.shippingCountryCode !== '00') {
                    this.storeShipping.setHasShippingAddress(true);
                } else {
                    this.storeShipping.setHasShippingAddress(false);
                }

                this.setState({
                    shippingClass: !this.state.shippingClass,
                    cartAddresses: cartAddresses
                })
            }

            showFormShippingAddress = () => {
                const shippingClass = true;

                this.setState({
                    shippingClass: shippingClass
                })
            }

            render() {
                return (
                    <TemplateForms
                        formClass = "register"
                        type = 'register'
                        msg_success = {i18n.t("form_feedback.success_register")}
                    >
                        <form className="form form--register">
                            {[...Array(this.fieldTitles.length)].map((x, idx) =>
                            <React.Fragment>
                                <div className="form-fieldset">
                                    <div
                                        className = "form__fieldset-title"
                                    >
                                        {this.fieldTitles[idx] !== 'address'
                                            ?   i18n.t("form_fieldset-title." + this.fieldTitles[idx])
                                            :   this.fieldTitles[idx] === 'address' && this.state.shippingClass
                                                ?   "Invoice address"
                                                :   "Invoice & shipping address"
                                        }
                                    </div>
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        (idx + 1) === this.state.formControls[key].group &&
                                            <React.Fragment>
                                                {(((this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password")
                                                    && (this.storeAuth.isLoggedin && key !== 'invoiceEmail2'))
                                                    || (!this.storeAuth.isLoggedin && key !== 'invoiceCountry_select')) &&
                                                    <RowText 
                                                        type = {this.state.formControls[key].type}
                                                        name = {key}
                                                        value = {this.state.formControls[key].value}
                                                        validationRules = {this.state.formControls[key].validationRules}
                                                        validationMsg = {this.state.formControls[key].validationMsg}
                                                        onValidate = {this.validateRow}
                                                        onBlur = {this.handleBlur}
                                                        disable = {this.state.formControls[key].disable}
                                                        readonly = {this.state.formControls[key].readonly}
                                                    />
                                                }

                                                {key === "invoiceCountry_select" &&
                                                    <RowSelect 
                                                        name = {key}
                                                        label = {this.state.formControls[key].label}
                                                        value = {this.state.formControls[key].value}
                                                        options = {this.state.formControls[key].options ? this.state.formControls[key].options : []}
                                                        validationRules = {this.state.formControls[key].validationRules}
                                                        onValidate = {this.validateRow}
                                                        selected = {this.state.formControls[key].value}
                                                        defaultValue = {this.state.formControls[key].value}
                                                        touched = {this.state.formControls[key].touched}
                                                    />
                                                }
                                               
                                                {key === "invoiceFirstname" && this.state.error_firstname &&
                                                    <span className="form-field--suggestion">{i18n.t("form_suggestions.firstname")}</span>
                                                }

                                                {((key === "invoiceEmail" && this.state.error_email) || (key === "invoiceEmail2" && this.state.error_email2)) &&
                                                    <span className="form-field--error --email">{i18n.t("form_errors.email_in_use_extended")}</span>
                                                }

                                                {key === "invoiceEmail2" && this.state.error_email_match &&
                                                    <span className="form-field--error --email">{i18n.t("form_errors.email_not_matching")}</span>
                                                }

                                                {key === "password2" && this.state.error_pw_match &&
                                                    <span className="form-field--error --pw">{i18n.t("form_errors.pw_not_matching")}</span>
                                                }
                                            </React.Fragment>
                                    ))}

                                    {this.fieldTitles[idx] === 'address' &&
                                        <div className="form-row form-row--buttons">
                                            <button 
                                                className={"btn btn--tertiary --active"} 
                                                onClick={(e) => this.toggleShipping(e)}
                                            >
                                                {this.state.shippingClass
                                                ?   'Hide shipping address'
                                                :   (this.state.cartAddresses && this.state.cartAddresses.shippingAddress !== undefined && this.props.addresses.shippingAddress !== null && this.state.cartAddresses.shippingAddress !== '' && this.storeCart.shippingCountryCode !== '' && this.storeCart.shippingCountryCode !== '00')
                                                    ?   'Show shipping address'
                                                    :   'Add different shipping address'
                                                }
                                            </button>
                                        </div>
                                    }

                                    {this.state.error &&
                                        <div className="form-row form-row--error">
                                            <p>{i18n.t("errors.error_general")}</p>
                                        </div>
                                    }
                                </div>
                                
                                {this.fieldTitles[idx] === 'address' && this.state.cartAddresses && this.state.shippingClass === true &&
                                    <FormShipping
                                        user = {this.props.user}
                                        addresses = {this.state.cartAddresses}
                                    />
                                }
                            </React.Fragment>
                            )}
                        </form>
                    </TemplateForms>
                )
            }
        }
    )
)

export default withTranslation()(FormCheckout);
