import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Router, Routes, Route } from 'react-router-dom';
import { withRouter } from "./withRouter";

import Start from './pages/start';
import Shop from './pages/shop';
import Faq from './pages/faq';
import Contact from './pages/contact';
import Login from './pages/login';
import Password from './pages/password';
import Account from './pages/account';
import MyOrders from './pages/my-orders';
import RegisterAccount from './pages/register-account';
import RegisterWholesale from './pages/register-wholesale';
import Product from './pages/product';

import About from './pages/about';
import Reseller from './pages/reseller';
import PrivacyPolicy from './pages/privacy-policy';
import ResetPassword from './pages/reset-password';

import Cart from './pages/shop/cart';
import Checkout from './pages/shop/checkout';
import Payment from './pages/shop/payment';
import PaymentResult from './pages/shop/payment-result';
import PaymentError from './pages/shop/payment-error';
import RedirectPayment from './components/redirect_payment';

import Error404 from './pages/error/error404';

const AppRoutes = inject("stores") (
    observer (
        class AppRoutes extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeOrders = this.props.stores.storeOrders;
            }

            render() {
                return (
                    <React.Fragment>
                        <Routes>
                            <Route 
                                path="/" 
                                element={<Start />}
                            />
                            <Route 
                                path="/shop" 
                                element={<Shop />}
                            />
                            <Route 
                                path="/faq" 
                                element={<Faq />}
                            />
                            <Route 
                                path="/contact" 
                                element={<Contact />}
                            />
                            <Route 
                                path="/login" 
                                element={<Login />}
                            />
                            <Route 
                                path="/password" 
                                element={<Password />}
                            />
                            <Route 
                                path="/account" 
                                element={<Account />}
                            />
                            <Route 
                                path="/my-orders" 
                                element={<MyOrders />}
                            />
                            <Route 
                                path="/register" 
                                element={<RegisterAccount />}
                            />
                            <Route 
                                path="/wholesale" 
                                element={<RegisterWholesale />}
                            />
                            <Route 
                                path="/product/:id" 
                                element={<Product />}
                            />
                            <Route 
                                path="/about" 
                                element={<About />}
                            />
                            <Route 
                                path="/reseller" 
                                element={<Reseller />}
                            />
                            <Route 
                                path="/privacy-policy" 
                                element={<PrivacyPolicy />}
                            />
                            <Route 
                                path="/reset-password" 
                                element={<ResetPassword />}
                            />
                            <Route 
                                path="/cart" 
                                element={<Cart />}
                            />
                            <Route 
                                path="/checkout" 
                                element={<Checkout />}
                            />
                            <Route 
                                path="/payment" 
                                element={<Payment />}
                            />
                            {/* <Route 
                                path="/paymentmsp" 
                                render={(routeProps) => (<RedirectPayment {...routeProps} loc={this.storeOrders.urlMsp} />)}
                            /> */}
                            {/* <Route 
                                path="/paymentmsp" 
                                element={<Navigate replace to="/home" />} 
                            /> */}
                            <Route 
                                path="/payment-result" 
                                element={<PaymentResult />}
                            />
                            <Route 
                                path="/payment-error" 
                                element={<PaymentError />}
                            />
                            <Route 
                                path='*' 
                                element={<Error404 />}
                            />
                        </Routes>
                    </React.Fragment>
                );
            }
        }
    )
)

export default withRouter(AppRoutes);