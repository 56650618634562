import { observable, computed, action } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as prods from '../communicator/products';

class StoreProducts {

    products = []

    constructor() {

    }

    async getProducts() {
        let products;
        try {
            const returnData = await prods.getProducts();
            runInAction(() => {
                products = returnData.data;
                this.products = products;
                return products;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return products;
    }

    async getProduct(id) {
        let product;
        try {
            const returnData = await prods.getProduct(id);
            runInAction(() => {
                product = returnData.data[0];
                this.product = product;
                return product;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return product;
    }
}

export default StoreProducts;