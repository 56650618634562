import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "../../withRouter";

const PriceRegular = inject("stores") (
    observer (
        class Li extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.state = {}
            }

            componentDidMount = () => {

            }
        
            render() {
                return (
                    <span
                        className = {"product__price"}
                    >
                        {this.props.product.priceIncl}
                    </span>
                )
            }
        }
    )
)

const PriceDiscount = inject("stores") (
    observer (
        class PriceDiscount extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeDiscount = this.props.stores.storeDiscount;

                this.state = {}
            }

            componentDidMount = () => {
                let priceDiscounted = this.storeDiscount.showPriceDiscount(this.props.product);
                priceDiscounted =(Math.ceil(priceDiscounted*20 - 0.5)/20).toFixed(2);

                this.setState({
                    priceDiscounted: priceDiscounted
                })               
            }
        
            render() {
                return (
                    <React.Fragment>
                        {this.props.product.discount > 0  &&
                            <span
                                className = "product__price--reduced"
                            >
                                <s>{this.props.product.priceIncl}</s>
                            </span>
                        }
                        <span
                            className = "product__price"
                        >
                            {this.state.priceDiscounted}
                        </span>

                        {this.props.view === 'discount' && this.props.product.discountMinAmount > 1 &&
                            <span
                                className = "product__price--addition"
                            >
                                When ordering a minimum of {this.props.product.discountMinAmount} {this.props.product.id === 4 ? 'packs' : 'tubes'}:
                            </span>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

const PriceWholesale = inject("stores") (
    observer (
        class PriceWholesale extends Component {
            
            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeDiscount = this.props.stores.storeDiscount;

                this.state = {}
            }

            componentDidMount = () => {
                const priceDiscounted = this.storeDiscount.showPriceWholesale(this.props.product);
               
                this.setState({
                    priceDiscounted: priceDiscounted
                })
            }

            componentDidUpdate = (prevProps) => {

            }
        
            render() {
                return (
                    <React.Fragment>
                        {this.props.product.discountWholesaleMinAmount > 1 &&
                            <span
                                className = "product__price--addition"
                            >
                                When ordering a minimum of {this.props.product.discountWholesaleMinAmount} {this.props.product.id === 4 ? 'packs' : 'tubes'}:
                            </span>
                        }
                        
                        {/* {this.props.product.discountWholesale > 0  &&
                            <span
                                className = "product__price--reduced"
                            >
                                <s>{this.props.product.priceIncl}</s>
                            </span>
                        } */}

                        <span
                            className = "product__price"
                        >
                            {this.state.priceDiscounted}
                        </span>
                    
                        {this.props.product.discountWholesaleMinAmount > 1 &&
                            <span
                                className = "product__price--addition"
                            >
                                per {this.props.product.id === 4 ? 'pack' : 'tube'}
                            </span>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

const ProductPrice = inject("stores") (
    observer (
        class ProductPrice extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;
                
                this.storeAuth = this.props.stores.storeAuth;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeDiscount = this.props.stores.storeDiscount;
                this.storeCart = this.props.stores.storeCart;

                this.state = {
                    
                }
            }

            componentDidMount = async() => {
                let priceDiscounted;

                if (parseInt(this.storeAuth.user.isReseller) === 1) {
                    priceDiscounted = this.storeDiscount.showPriceWholesale(this.props.product);
                } else {
                    priceDiscounted = this.storeDiscount.showPriceDiscount(this.props.product);
                }
               
                this.setState({
                    priceDiscounted: priceDiscounted
                })
            }

            componentDidUpdate = () => {

            }

            downloadPdf = (lang) => {
                const file = this.urlPublic + "/public/pdf/leafletProgesterine-" + lang + ".pdf";
                window.open(file);
            }

            gotoProduct = (sku) => {
                const url = "/product/" + sku;
                this.props.navigate(url);
            }

            addToCart = async() => {
                let price = this.props.product.priceIncl;
                let reseller = false;

                if (parseInt(this.storeAuth.user.isReseller) === 1) {
                    price = this.state.priceDiscounted;
                    reseller = true;
                } else {
                    price = this.props.product.priceIncl; 
                    reseller = false;
                }

                this.storeCart.addToCart(this.props.product, price, reseller);
            }
        
            render() {
                return (
                    <React.Fragment>
                        {this.props.view === 'regular' &&
                            <div 
                                className = "product__data-price"
                            >
                                <PriceRegular
                                    product = {this.props.product}
                                    view = {this.props.view}
                                />
                            </div>
                                
                        }
                        {(this.props.view === 'discount' && this.props.product.discount > 0) &&
                            <div 
                                className = "product__data-price"
                            >
                                <PriceDiscount
                                    product = {this.props.product}
                                    view = {this.props.view}
                                />
                            </div>
                        }
                        {this.props.view === 'wholesale' && parseInt(this.storeAuth.user.isReseller) === 1 &&
                            <div 
                                className = "product__data-price"
                            >
                                <PriceWholesale
                                    product = {this.props.product}
                                    view = {this.props.view}
                                />
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(ProductPrice);
