import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { withRouter } from "../../withRouter";
//import { withRouter } from 'react-router-dom';

const Navigation = inject("stores") (
    observer (
        class Navigation extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeCart = this.props.stores.storeCart;

                this.state = {
                    navMobile: false
                }

                this.toggleRef = React.createRef();
                this.wrapperRef = React.createRef();
                this.handleClickOutside = this.handleClickOutside.bind(this);
            }

            componentDidMount = () => {
                document.addEventListener("mousedown", this.handleClickOutside);
            }

            componentDidUpdate = (prevProps) => {

            }

            handleClickOutside(event) {
                if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && !this.toggleRef.current.contains(event.target)) {
                    this.setState({
                        navMobile: false
                    })
                }
              }

            setLogout = () => {
                const isLoggedIn = this.storeAuth.setLogoutState();
                
                this.setState({
                    navMobile: false
                })

                const url = "/";
                this.props.navigate(url);
            }

            navigatieToPage = (page) => {              
                let url = "/";
                let origin = this.props.location.pathname;

                if (page !== 'home') {
                    url = "/" + page;
                    origin = origin.substring(1);
                } 

                if (page === 'login') {
                   this.storeGeneral.setOrigin(origin);
                } 
                
                this.props.navigate(url);
            }

            gotoPage = (page) => {
                this.setState({
                    navMobile: !this.state.navMobile
                })
                
                let url = "/";
                if (page !== 'home') {
                    url = "/" + page;
                }

                this.props.navigate(url);
            }

            toggleNavigationMobile = (event) => {
                if (!this.wrapperRef.current.contains(event.target)) {
                    this.setState({
                        navMobile: !this.state.navMobile
                    })
                }
            }
        
            render() {
                return (
                    <div className="wrapper-navigation">
                        <ul className="navigation__list">
                            <li className="navigation__listitem"><Link to="/">Home</Link></li>
                            <li className="navigation__listitem"><Link to="/shop">Shop</Link></li>
                            <li className="navigation__listitem"><Link to="/faq">Faq</Link></li>
                            {!this.storeAuth.isLoggedin &&
                                <React.Fragment>
                                    <li className="navigation__listitem"><Link to="/register">Register</Link></li>
                                </React.Fragment>
                            }
                            {parseInt(this.storeAuth.user.isReseller) !== 1 &&
                                <li className="navigation__listitem"><Link to="/wholesale">Wholesale registration</Link></li>
                            }
                            {!this.storeAuth.isLoggedin &&
                                <React.Fragment>
                                    <li 
                                        className="navigation__listitem --link"
                                        onClick = {() => this.navigatieToPage('login')}
                                    >
                                        Login
                                    </li>
                                </React.Fragment>
                            }
                            {this.storeAuth.isLoggedin &&
                                <React.Fragment>
                                    <li className="navigation__listitem"><Link to="/account">My account</Link></li>
                                    <li 
                                        className="navigation__listitem --link"
                                        onClick = {() => this.setLogout()}
                                    >
                                        Logout
                                    </li>
                                </React.Fragment>
                            }
                            <li 
                                ref={this.toggleRef}
                                className="navigation__listitem--mobile-nav-trigger --link"
                                onClick={(e) => this.toggleNavigationMobile(e)}
                            >
                                    MENU
                            </li>
                        </ul>

                        <ul 
                            ref={this.wrapperRef}
                            className={"navigation--mobile__list " + (this.state.navMobile ? "--active" : "")}
                        >
                            <li 
                                className="navigation--mobile__listitem --link"
                                onClick = {() => this.gotoPage('home')}
                            >
                                Home
                            </li>
                            <li 
                                className="navigation--mobile__listitem --link"
                                onClick = {() => this.gotoPage('shop')}
                            >
                                Shop
                            </li>
                            <li 
                                className="navigation--mobile__listitem --link"
                                onClick = {() => this.gotoPage('faq')}
                            >
                                Faq
                            </li>
                            {!this.storeAuth.isLoggedin &&
                                <li 
                                    className="navigation--mobile__listitem --link"
                                    onClick = {() => this.gotoPage('register')}
                                >
                                    Register
                                </li>
                            }
                            {parseInt(this.storeAuth.user.isReseller) !== 1 &&
                                <li 
                                    className="navigation--mobile__listitem --link"
                                    onClick = {() => this.gotoPage('wholesale')}
                                >
                                    Wholesale registration
                                </li>
                            }
                            {!this.storeAuth.isLoggedin &&
                                <li 
                                    className="navigation--mobile__listitem --link"
                                    onClick = {() => this.gotoPage('login')}
                                >
                                    Login
                                </li>
                            }
                            {this.storeAuth.isLoggedin &&
                                <React.Fragment>
                                    <li 
                                        className="navigation--mobile__listitem --link"
                                        onClick = {() => this.gotoPage('account')}
                                    >
                                        My account
                                    </li>
                                    <li 
                                        className="navigation--mobile__listitem --link"
                                        onClick = {() => this.setLogout()}
                                    >
                                        Logout
                                    </li>
                                </React.Fragment>
                            }
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Navigation);
