import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

const Welcome = inject("stores") (
    observer (
        class Welcome extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_API_PUBLIC;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUser = this.props.stores.storeUser;
                this.storePageContent = this.props.stores.storePageContent;

                this.state = {

                }
            }

            componentDidMount = async() => {
                const user = await this.getUser();
                const page = "welcome";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);
                
                this.setState({
                    title: title,
                    blocks: blocks,
                    user: user
                })
            }
    
            componentDidUpdate = () => {

            }

            getUser = async() => {
                const id = this.storeAuth.user.id;
                const email = this.storeAuth.user.email;
                const user = await this.storeUser.getUserData(id, email);

                return user;
            }
       
            render() {
                return (
                    <div
                        key = {this.props.key}
                        className = "wrapper-content wrapper-content--home"
                    >
                        <div
                            className = "wrapper-content__columns"
                        >
                            <div
                                className = "wrapper-content__column wrapper-content__column--start"
                            >
                                {this.storeAuth.isLoggedin 
                                ?   <React.Fragment>
                                        <h1>
                                            Welcome back {this.state.user && this.state.user.firstname}
                                        </h1>
                                        <p>We are glad to see you again.</p>
                                    </React.Fragment>
                                :   <React.Fragment>
                                        <h1>
                                            Welcome
                                        </h1>
                                        {this.state.blocks && this.state.blocks.map((block, i) => (
                                            <div
                                                key = {i}
                                            >
                                                <p
                                                    dangerouslySetInnerHTML={{ __html: block.textEN}}
                                                />
                                            </div>
                                        ))}
                                    </React.Fragment>
                                }
                            </div>
                            <div
                                className = "wrapper-content__column wrapper-content__column--start"
                            >
                                <Link to="/shop">
                                    <img 
                                        src = {this.urlPublic + "/public/images/product/9789079872021.png"} 
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default Welcome;
